import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    { text: "锦隆科技信息公司介绍", value: "锦隆科技信息公司介绍" },
    {
        text: "我是锦隆科技市场部小隆,美国客户James反馈我们发货太慢，主要原因是台风导致船舶公司停运，帮我写一封邮件。",
        value: "我是锦隆科技市场部小隆，美国客户James反馈我们发货太慢，主要原因是台风导致船舶公司停运，帮我写一封邮件。"
    },
    {
        text: "深圳锦隆信息技术有限公司，国庆节放五天假，2023年9月29号开始放，帮我写一则放假通知，在2023年9月25日发布",
        value: "深圳锦隆信息技术有限公司，国庆节放五天假，2023年9月29号开始放，帮我写一则放假通知，在2023年9月25日发布"
    }
];

const EXAMPLES_INNER_DATA: ExampleModel[] = [
    //内部资料
    { text: "我需要找锦隆购买azure,应该联系谁？", value: "我需要找锦隆购买azure,应该联系谁？" },
    {
        text: "深圳锦隆主要销售什么产品？",
        value: "深圳锦隆主要销售什么产品？"
    },
    {
        text: "深圳锦隆的企业文化是什么？",
        value: "深圳锦隆的企业文化是什么？"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};

export const ExampleListInnerData = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES_INNER_DATA.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
